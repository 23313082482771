const { React, useEffect, useState } = require('react')

const App = () => {
  const [jobPostingsData, setJobPostingsData] = useState([]);

  useEffect(() => {
    fetchData().then((data) => setJobPostingsData(data));
  }, []);

  return (
    <div className="bg-gray-100 font-sans">
      <div className="container mx-auto p-8">
        {/* Intro Section */}
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Welcome to Indy Engineering</h1>
          <p className="text-gray-600 mb-8">Explore and submit job postings for software engineering positions in Indianapolis.</p>
          <a href="https://tally.so/r/mK11P8" target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded inline-block hover:bg-blue-600">Submit a Job Posting</a>
        </div>

        {/* Job Postings Section */}
        <div id="jobPostings" className="mt-12">
          {/* Job postings will be dynamically added here */}
          {jobPostingsData.map((posting, index) => (
            <div key={index} className="bg-white shadow-md rounded-md p-6 mb-6">
              <h3 className="text-xl font-semibold mb-2">{posting.companyName}</h3>
              <p className="text-gray-600 mb-2">Company: {posting.jobTitle}</p>
              <p className="text-gray-700">Location: {posting.jobDescription}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const fetchData = async () => {
  try {
    const response = await fetch("https://joblist.tylorg.workers.dev/");

    if (!response.ok) {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }

    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching data:', error.message);
  }
};

export default App;
